import React from 'react';
import Layout from '../../shared/Layout';
import SEO from '../../shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './singleNewsPage.module.scss';
import Footer from "../../shared/Footer/Footer";
import {Link, graphql} from "gatsby";
import Img from "gatsby-image";
import MainButton from "../../shared/MainButton/MainButton";
import {getLink, getRoutes} from "../../shared/routes";
import Tags from "../../shared/Tags/Tags";
import LatestNewsList from "./LatestNewsList";
import NewsDate from "../../shared/NewsDate/NewsDate";
import ArrowRight from "../../../images/icons/arrow_right_green_fill.svg";

/*
  NOTES:

  This file is used for our company internal news single page by gatsby-node.
  (Change entry in gatsby-node if rename this file.)

  When you add new field to frontmatter add it to content/news/template/index.md too.
  Why?
  Template file isn't rendered anywhere, but is read by graphql.
  Graphql needs at least one MD file with field used in query.
  If you add field to your news md file only it will work until someone will remove this news in the feature -
  - then application will build sometimes only, so it's hard to debug.
 */
export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        newsMarkdownTemplateFile
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 912, quality: 70) {
              ...GatsbyImageSharpFluid,
              originalName
            }
          }
        }
        imageDescription
        youtube
        copyright
        externalLink
      }
    }
  }
`;

const SingleNewsPage = props => {
  const { t } = useTranslation();

  const { data } = props;

  const post = data.markdownRemark;

  const {
    title,
    tags,
    copyright,
    imageDescription,
    image,
    youtube,
    date,
    externalLink
  } = post.frontmatter;

  if (!title) {
    return null;
  }

  const pageTitle = `${title} | ${getRoutes().news.pageTitle}`;

  const newsContent = (
    <div
      dangerouslySetInnerHTML={{
        __html: post.html,
      }}
    />
  );

  const { newsMarkdownTemplateFile } = data.site.siteMetadata;
  const { previous, next } = props.pageContext;
  const isPrevLink = (previous && previous.fields.slug !== newsMarkdownTemplateFile);
  const isNextLink = (next && next.fields.slug !== newsMarkdownTemplateFile);

  const getImageMovieContent = () => {
    if (image) {
      const imageFluid = (image.childImageSharp && image.childImageSharp.fluid) || "";
      return (
        <div className={css.imageWrapper}>
          { imageFluid && <Img fluid={imageFluid} alt={imageDescription} />}
          <div className={css.overlay}>
            { copyright && <div className={css.copyright}>&copy; {copyright}</div> }
          </div>
        </div>
      );
    }
    if (youtube) {
      const src = `https://www.youtube.com/embed/${youtube}`;
      return (
        <iframe
          className={css.movieWrapper}
          title={src}
          width="100%"
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    }
    return null;
  };

  const linkToAllNewsPage = getLink([getRoutes().news]);

  /*
  Uncomment it if don't wanna render external news single page.
  Now they are rendered for robots indexing, but they are not linked directly on page.
   */
  /*if (externalLink) {
    if (typeof window !== 'undefined') {
      window.location = '/';
    }
    return null;
  }*/

  return (
    <Layout>
      <SEO title={pageTitle}/>

      <div className={css.wrapper}>

        <div className={css.singleNewsWrapper}>
          <div className={css.contentWrapper}>

            <div className={css.tagsWrapper}>
              <Tags stringTags={tags} />
            </div>

            <h1 className={css.newsTitle}>{title}</h1>

            <div className={css.date}><NewsDate date={date} /></div>

            <div className={css.imageMovieContent}>{getImageMovieContent()}</div>

            {externalLink &&
              <div className={css.externalLink}>
                <MainButton
                    href={externalLink}
                    label={<>Read Article<img src={ArrowRight} alt="" className={css.arrowRight} /></>}
                    color={'green'}
                />
              </div>
            }

            <article className={css.newsContent}>{newsContent}</article>

            <div className={css.navigation}>
              <div className={css.backToNewsList}>
                <Link to={linkToAllNewsPage}>
                  <MainButton label="All news" isLink={false} _tight color={'green'}  />
                </Link>
              </div>
              {/*<div className={css.prevNext}>
                <div className={css.prevNextLinkWrapper}>
                  {isPrevLink && (
                    <Link to={previous.fields.slug} rel="prev" className={css.prev}>
                      <MainButton label={<>&laquo;</>} isLink={false} tight />
                      <div className={css.prevNewsTitle}>{previous.frontmatter.title}</div>
                    </Link>
                  )}
                </div>
                <div className={css.prevNextLinkWrapper}>
                  {isNextLink && (
                    <Link to={next.fields.slug} rel="next" className={css.next}>
                      <div className={css.nextNewsTitle}>{next.frontmatter.title}</div>
                      <MainButton label={<>&raquo;</>} isLink={false} tight />
                    </Link>
                  )}
                </div>
              </div>*/}
            </div>
          </div>

          <aside className={css.sidebarWrapper}>
            <div className={css.latestNewsWrapper}>
              <LatestNewsList />
            </div>
            <div className={css.facebookWrapper}>
              {/* if change width/height in iframe src, set $iframeWidth in css for same value */}
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fai4creative&tabs=timeline&width=280&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                scrolling="no"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"/>
            </div>
          </aside>

        </div>

        <div className={css.Footer}>
          <Footer />
        </div>

      </div>
    </Layout>
  );
};

export default SingleNewsPage;
