import {graphql, Link, useStaticQuery} from 'gatsby';
import React from "react";
import css from './latestNewsList.module.scss';
import Img from 'gatsby-image';
import LatestNewsTemplate from "./LatestNewsTemplate";
import MainButton from "../../shared/MainButton/MainButton";
import {getLink, getRoutes} from "../../shared/routes";

/*
NOTE: If add field to frontmatter, add this field to content/news/template/index.md
Graphql with Gatsby needs at least one md file with fields getting here.
Template is used to copy and create any news.
 */
const query = graphql`
  query {
    allMarkdownRemark(limit: 5, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            externalLink
            image {
              childImageSharp {
                fluid(maxWidth: 84, quality: 70) {
                  ...GatsbyImageSharpFluid,
                  originalName
                }
              }
            }
            imageDescription
            youtube
          }
        }
      }
    }
    site {
      siteMetadata {
        newsMarkdownTemplateFile
      }
    }
  }
`;

const LatestNewsList = props => {
  const data = useStaticQuery(query);

  const { newsMarkdownTemplateFile } = data.site.siteMetadata;

  const posts = data.allMarkdownRemark.edges.filter(post => post.node.fields.slug !== newsMarkdownTemplateFile);

  const getLinkToSingleNewsPage = (externalLink, internalLink) => {
    if (!!externalLink) {
      return externalLink;
    }
    return internalLink;
  };

  const getImageMovieContent = frontmatter => {
    if (frontmatter.image) {
      const image = (frontmatter.image.childImageSharp && frontmatter.image.childImageSharp.fluid) || "";
      return image && <Img fluid={image} alt={frontmatter.imageDescription} className={css.imageByGatsby} />
    }
    if (frontmatter.youtube) {
      const thumbnailUrl = `https://img.youtube.com/vi/${frontmatter.youtube}/0.jpg`;
      return (
        <div className={css.imageByYoutube}>
          <img src={thumbnailUrl} alt={frontmatter.imageDescription} />
        </div>
      );
    }
    return null;
  };

  const mapPosts = () => {
    return posts.map((post, index) => {
      const { node = {} } = post;
      const { frontmatter = {}, fields = {} } = node;
      const slug = fields.slug;
      const {
        externalLink = '',
        date = ''
      } = frontmatter;

      const title = node.frontmatter.title || slug;
      const newLink = getLinkToSingleNewsPage(externalLink, slug);

      return (
        <div key={slug} className={css.newsWrapper}>
          <LatestNewsTemplate
            external={!!externalLink}
            content={getImageMovieContent(frontmatter)}
            title={title}
            link={newLink}
            date={date}
          />
        </div>
      );
    })
  };

  const linkToAllNewsPage = getLink([getRoutes().news]);

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.title}>
          Latest News
        </div>
        <div className={css.button}>
          <Link to={linkToAllNewsPage}>
            <MainButton label="See all" isLink={false} tight color={'green'} />
          </Link>
        </div>
      </div>
      <div className={css.allNewsWrapper}>
        {mapPosts()}
      </div>
    </div>
  )
};

export default LatestNewsList;
